const categoriesProductsModule = {
	namespaced: true,
	state: {
		categoriesList: [],
		categoriesListPromise: undefined,
	},
	getters: {
	},
	mutations: {
		setCategoriesList(state, categoriesList) {
			state.categoriesList = categoriesList;
		},
		setCategoriesListPromise(state, categoriesListPromise) {
			state.categoriesListPromise = categoriesListPromise;
		},
	},
	actions: {
		getCategoriesList({ state, commit }, { axios, cid }) {
			if (state.categoriesList.length) {
				return state.categoriesList;
			}
			if (state.categoriesListPromise && state.categoriesListPromise instanceof Promise) {
				return state.categoriesListPromise;
			}
			const promise = axios.post(`/api/category`, { id: cid }).then((res) => {
				commit("setCategoriesList", res.data);
				return res;
			});
			commit("setCategoriesListPromise", promise);
			return promise;
		},
		clearCategoriesList({ state, commit }) {
			commit("setCategoriesList", []);
			commit("setCategoriesListPromise", undefined);
		},
	},
	modules: {
	}
}

export default categoriesProductsModule;