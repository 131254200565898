<template>
	<b-modal v-model="showModal" modal-class="advertisementModal" hide-header hide-footer centered>
		<button class="btn-close" @click="showModal = false"></button>
		<template v-if="advertisement.popup">
			<img :src="advertisement.popup" class="img-fluid">
			<!-- <router-link v-if="advertisement.popup.sameDomain" :to="advertisement.popup.url">
				<img :src="advertisement.popup.image" :alt="advertisement.popup.name" class="img-fluid">
			</router-link>
			<a v-else :href="advertisement.popup.url">
				<img :src="advertisement.popup.image" :alt="advertisement.popup.name" class="img-fluid">
			</a> -->
		</template>
	</b-modal>
</template>
<script>
import { getCurrentInstance, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
	setup(props) {
		const globalProperties = getCurrentInstance().appContext.config.globalProperties;
		const storage = globalProperties.$storage;
		const route = useRoute();
		const showModal = ref(false);
		const closeAdvertisementTimer = ref();
		const advertisement = reactive({
			popup: undefined,
			setting: undefined,
		});

		if (!globalProperties.isServer) {
			const show = async (routeName) => {
				await getAdvertisementInfo();

				const advertisementStorage = storage.getStorageSync("advertisement");
				if (
					!advertisementStorage.popup ||
					!advertisementStorage.setting ||
					!advertisementStorage.setting.pages.includes(routeName) ||
					advertisementStorage.setting.times <= 0
				) {
					return;
				}

				Object.assign(advertisement, storage.getStorageSync("advertisement"));
				showModal.value = true;
				advertisement.setting.times -= 1;
				storage.setStorageSync("advertisement", advertisement);

				closeAdvertisementTimer.value = setTimeout(() => {
					showModal.value = false;
				}, advertisement.setting.seconds * 1000);
			}

			const getAdvertisementInfo = async () => {
				const { data: { popup, setting } } = await globalProperties.axios.post("/api/popup");
				// if (popup && globalProperties.$filters.isSameDomain(popup)) {
				// 	// popup.url = new URL(popup.url).pathname;
				// 	popup.sameDomain = true;
				// }
				if (!popup || !setting) {
					storage.setStorageSync("advertisement", {
						popup,
						setting,
					});
					return;
				}

				const advertisementStorage = storage.getStorageSync("advertisement");
				if (
					!advertisementStorage ||
					// advertisementStorage.popup && advertisementStorage.popup.updated_at !== popup.updated_at ||
					storage.isExpire("advertisementCycleExpire")
				) {
					setting.pages = setting.pages.reduce((accumulatorShow, page) => {
						if (page.status) {
							accumulatorShow.push(page.name);
						}
						return accumulatorShow;
					}, []);

					storage.setStorageSync("advertisement", {
						popup,
						setting,
					});
					storage.setStorageSync("advertisementCycleExpire", null, setting.cycle * 1000);
				}
			}

			watch(() => route.path, (newPath, oldPath) => {
				if (closeAdvertisementTimer.value) {
					clearTimeout(closeAdvertisementTimer.value);
				}
				showModal.value = false;
				show(route.name);
			});
			show(route.name);
		}
		return {
			showModal,
			advertisement,
		}
	}
}
</script>
<style lang="scss">
.advertisementModal {
	.modal-dialog {
		width: fit-content;
		max-width: 700px;
		.modal-content {
			background: transparent;
			border: none;
			.modal-body {
					padding: 0;
				.btn-close {
					right: 0;
					position: absolute;
					padding: 1rem;
					background-size: 1.3rem;
				}
				a {
					display: block;
				}
			}
		}
	}
}
</style>